exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blue-check-instagram-facebook-tiktok-js": () => import("./../../../src/pages/blue-check-instagram-facebook-tiktok.js" /* webpackChunkName: "component---src-pages-blue-check-instagram-facebook-tiktok-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-ecommerce-development-js": () => import("./../../../src/pages/custom-ecommerce-development.js" /* webpackChunkName: "component---src-pages-custom-ecommerce-development-js" */),
  "component---src-pages-ecommerce-seo-js": () => import("./../../../src/pages/Ecommerce-SEO.js" /* webpackChunkName: "component---src-pages-ecommerce-seo-js" */),
  "component---src-pages-facebook-ads-js": () => import("./../../../src/pages/facebook-ads.js" /* webpackChunkName: "component---src-pages-facebook-ads-js" */),
  "component---src-pages-google-ads-js": () => import("./../../../src/pages/google-ads.js" /* webpackChunkName: "component---src-pages-google-ads-js" */),
  "component---src-pages-hosting-migration-js": () => import("./../../../src/pages/hosting-migration.js" /* webpackChunkName: "component---src-pages-hosting-migration-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-ads-js": () => import("./../../../src/pages/instagram-ads.js" /* webpackChunkName: "component---src-pages-instagram-ads-js" */),
  "component---src-pages-international-seo-js": () => import("./../../../src/pages/International-SEO.js" /* webpackChunkName: "component---src-pages-international-seo-js" */),
  "component---src-pages-local-seo-js": () => import("./../../../src/pages/Local-SEO.js" /* webpackChunkName: "component---src-pages-local-seo-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-seoanalysis-js": () => import("./../../../src/pages/seoanalysis.js" /* webpackChunkName: "component---src-pages-seoanalysis-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-tiktok-ads-js": () => import("./../../../src/pages/tiktok-ads.js" /* webpackChunkName: "component---src-pages-tiktok-ads-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */),
  "component---src-pages-website-maintenance-packages-js": () => import("./../../../src/pages/website-maintenance-packages.js" /* webpackChunkName: "component---src-pages-website-maintenance-packages-js" */),
  "component---src-pages-wordpress-maintenance-packages-js": () => import("./../../../src/pages/wordpress-maintenance-packages.js" /* webpackChunkName: "component---src-pages-wordpress-maintenance-packages-js" */),
  "component---src-pages-youtube-ads-js": () => import("./../../../src/pages/youtube-ads.js" /* webpackChunkName: "component---src-pages-youtube-ads-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-industry-page-js": () => import("./../../../src/templates/IndustryPage.js" /* webpackChunkName: "component---src-templates-industry-page-js" */),
  "component---src-templates-sanity-blog-js": () => import("./../../../src/templates/sanity-blog.js" /* webpackChunkName: "component---src-templates-sanity-blog-js" */)
}

