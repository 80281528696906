module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RankingPT","short_name":"RankingPT","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5e04d89c14c0268cbf6b3915ee8f8a10"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["en","fr","pt"],"defaultLanguage":"en","siteUrl":"https://www.rankingpt.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/blog/","getLanguageFromPath":false},{"matchPath":"/blog/:uid","getLanguageFromPath":false},{"matchPath":"/category/:uid","getLanguageFromPath":true},{"matchPath":"/industries/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/:uid","getLanguageFromPath":true}],"redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
